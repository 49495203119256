
  
  .formContainer {
    width: 33%;
  }
  
  a {
    color: rgb(231, 231, 231);
    text-decoration: none;
  }
  
  li {
    list-style: none;
  }
  
  input {
    position: relative;
    height: 100%;
  }
  
  input[type="email"] {
    padding: 7px 8px;
    border: 1px solid rgb(206, 206, 206);
  }
  input[type="button"] {
    border: none;
    padding: 7px 12px;
    background: rgb(79, 223, 79);
    color: rgb(255, 255, 255);
    border: 1px solid rgb(79, 223, 79);
    cursor: pointer;
  }
  
  main {
    flex: 1;
  }
  
  footer {
    bottom: 0;
    background: rgb(46, 46, 46);
    color: white;
    width: 100vw;
    max-width: 100vw;
    overflow-x: hidden;
  }

  
  /* Responsive adjustments */
  @media screen and (max-width: 768px) {
    footer .top {
      flex-direction: column;
    }
    footer .top .newsletter {
      padding-top: 1rem;
    }
    footer .top .pages ul {
      margin: 10px;
    }
    .formContainer {
        width: 50%;
      }
  }
  
  @media screen and (max-width: 600px) {
    footer .top .pages {
      flex-wrap: wrap;
      justify-content: space-evenly;
    }
  
    footer .info {
      flex-direction: column;
    }
  
    footer .info > .copyright {
      margin-top: 10px;
    }
  }
  
  @media screen and (max-width: 380px) {
    footer .top .pages {
      justify-content: center;
      flex-direction: column;
    }
  
    footer .info .legal {
      justify-content: center;
    }
  
    footer .info .legal a {
      margin: 0;
      text-align: center;
    }
  }
  