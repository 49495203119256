.aboutUsContiner{
    color: rgb(119, 119, 119);
}

.aboutContainer{
    height: 150px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center; 
    align-items: center;
    color: #738ca0;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Verdana, sans-serif;
    box-shadow: 0px 5px 6px #999;
}

h2{
    font-size: 60px;
}

p{
    font-size: 14px;
}

.listContainer{
    font-size: 14px;
}

.emailSpan{
    text-decoration: underline;
}