.container{
    width: 100%;
}

.welcomeContainer{
    height: 150px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center; 
    align-items: center;
    color: #738ca0;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Verdana, sans-serif;
    box-shadow: 0px 5px 6px #999;
}

h2{
    font-size: 60px;
}

.teachingContainer {
    border-bottom: 1px solid #738ca0;
    padding-bottom: 10px;
    font-weight: 600;
}

.infoContainer{
    color: #738ca0;
    font-weight: 700;
}

@media only screen and (min-width: 768px) {
    .teachingContainer {
        border-right: 1px solid #738ca0;
        border-bottom: none;
    }
    .teachingContainer{
        padding: 0 24px;
    }
}